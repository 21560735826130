<template>
  <div class="box">
    <el-form :model="info" label-position="left" :rules="rules" ref="ruleForm" label-width="80px" size="small">
      <el-form-item label="选择门店" prop="shop_id" v-if="shopList && shopList.length>0">
        <el-select v-model="info.store_id" placeholder="请选择门店" size="small" style="width: 100%">
          <el-option
              v-for="item in shopList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import util from "@/utils/util";
export default {
  data() {
    return {
      info: {
        id: '',
        store_id: '',
        title: '',
      },
      rules: {
        title: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
      }
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  props: {
    shopList: Array,
    menuInfo: Object
  },
  watch: {
    'menuInfo': {
      handler (newValue, oldValue) {
        if(newValue) {
          this.info = newValue
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    submitForm(formName) {
      var _this = this
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          var param = JSON.parse(JSON.stringify(this.info))
          this.$api.operate.sourceAdd(param,function (res) {
            if(res.errcode == 0) {
              _this.success(res.errmsg)
              _this.$emit('callFunc');
            } else {
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
</style>
